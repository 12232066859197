<template>
  <v-container>
    <!-- Start Area section -->
    <v-row class="mx-5">
      <v-autocomplete
        v-model="selected_district"
        :items="districts"
        @input="getThana"
        item-text="Area_name"
        label="Select District"
        style="max-width: 500px"
        color="deep-purple accent-4"
        return-object
      ></v-autocomplete>
    </v-row>
    <v-row class="mx-5">
      <div>
        <p>Name : {{ selected_district.Area_name }}</p>
        <p>Details : {{ selected_district.Area_details }}</p>
        <p>
          Active Status :
          {{ selected_district.is_active ? "Active" : "Inactive" }}
        </p>
        <div
          v-if="
            selected_district &&
            Object.keys(selected_district).length &&
            selected_district.constructor === Object
          "
        >
          <v-btn
            v-if="selected_district.is_active"
            color="red"
            elevation="0"
            @click="districtStatusChage(0)"
            small
            >Make Inactive</v-btn
          >
          <v-btn
            v-else
            color="primary"
            elevation="0"
            @click="districtStatusChage(1)"
            small
            >Make Active</v-btn
          >
        </div>
      </div>
    </v-row>
    <!-- End Area section -->

    <!-- Start Thana section -->
    <v-data-table
      v-if="selected_district.is_active"
      item-key="name"
      class="elevation-0 mt-5"
      :loading="loading"
      :items="thanas"
      :headers="thana_headers"
      :search="search"
      loading-text="Loading... Please wait"
    >
      <template v-slot:top>
        <v-toolbar elevation="0" class="mt-2">
          <v-spacer></v-spacer>
          <div>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              filled
              rounded
              dense
              hide-details
              color="deep-purple accent-4"
            ></v-text-field>
          </div>
        </v-toolbar>
      </template>
      <template v-slot:item.is_active_state="{ item }">
        <v-chip v-if="item.is_active" dark color="primary"> Active </v-chip>
        <v-chip v-else dark color="red"> Inactive </v-chip>
      </template>
      <template v-slot:item.change_status="{ item }">
        <v-btn
          v-if="item.is_active"
          dark
          color="red"
          text
          @click="thanaStatusChage(item, 0)"
        >
          Make Inactive
        </v-btn>
        <v-btn
          dark
          color="primary"
          text
          v-else
          @click="thanaStatusChage(item, 1)"
        >
          Make Active
        </v-btn>
      </template>
      <template v-slot:item.add_charge="{ item }">
        <v-btn
          @click="addCharge(item)"
          fab
          color="deep-purple accent-4"
          elevation="0"
          small
          :disabled="!item.is_active"
        >
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </template>
    </v-data-table>
    <!-- Start Thana section -->

    <!-- Start Snackbar  -->
    <v-snackbar v-model="snackbar" color="white" centered white>
      <p class="mb-0 black--text">
        {{ text }}
      </p>
      <template v-slot:action="{ attrs }">
        <v-btn :color="btnColor" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
    <!-- Start Snackbar  -->

    <!-- Start Add charge section -->
    <v-dialog v-model="dialog" max-width="350">
      <v-card>
        <v-card-title class="headline"> Add Delivery Charge </v-card-title>
        <v-container>
          <v-row>
            <v-col cols="12"
              ><v-text-field
                label="Unit Price"
                type="number"
                color="deep-purple accent-4"
                v-model.number="assign_thana.unit_price"
              ></v-text-field>

              <v-text-field
                label="Delivery Day"
                type="number"
                color="deep-purple accent-4"
                v-model.number="assign_thana.delivery_day"
              ></v-text-field>
              <v-text-field
                label="minimum Amount"
                type="number"
                color="deep-purple accent-4"
                v-model.number="assign_thana.minimum_amount"
              ></v-text-field>

              <v-btn
                color="deep-purple accent-4"
                elevation="0"
                class="mt-3"
                block
                @click="onSaveThana"
              >
                Save
              </v-btn>
              <v-btn
                color="red"
                elevation="0"
                class="mt-3"
                block
                @click="dialog = false"
              >
                Cancel
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-dialog>
    <!-- Start Add charge section -->
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      loading: false,
      districts: [],
      selected_district: {},
      thanas: [],
      thana_headers: [
        {
          text: "Location Name",
          align: "start",
          sortable: false,
          value: "location_name",
          class: "custom-header black--text text-h5",
          divider: true,
        },
        {
          text: "Active Status",
          value: "is_active_state",
          class: "custom-header black--text text-h5",
          divider: true,
          align: "center",
        },
        {
          text: "Action",
          value: "change_status",
          class: "custom-header black--text text-h5",
          divider: true,
          align: "center",
        },
        {
          text: "Add Charge",
          value: "add_charge",
          class: "custom-header black--text text-h5",
          divider: true,
          align: "center",
        },
      ],
      text: "",
      snackbar: false,
      btnColor: "",
      dialog: false,
      search: "",

      assign_thana: {},
    };
  },
  methods: {
    // get all area
    getArea() {
      axios.get(`supports/getallareas/`).then((response) => {
        if (response.data.success) {
          this.districts = response.data.data;

          if (this.districts.length) {
            this.selected_district = this.districts[0];
            this.getThana();
          }
        }
      });
    },

    // Get thana
    getThana() {
      this.loading = true;
      axios
        .get(
          `supports/get_specific_locations/${this.selected_district.Area_name}/`
        )
        .then((response) => {
          this.loading = false;
          if (response.data.success) {
            this.thanas = response.data.data;
          } else {
            this.thanas = [];
          }
        })
        .catch((err) => {
          this.thanas = [];
          this.loading = false;
        });
    },

    // Change district status change
    districtStatusChage(status) {
      axios
        .get(
          `supports/district_change_status/${this.selected_district.Area_name}/${status}`
        )
        .then((response) => {
          if (status) {
            this.selected_district.is_active = true;
            this.createSnackbar("Activated successfully");
          } else {
            this.selected_district.is_active = false;
            this.createSnackbar("Deactivated successfully");
          }
        })
        .catch((err) => {
          this.createSnackbar("Something went wrong");
        });
    },

    // Thana status change
    thanaStatusChage(thana, status) {
      axios
        .get(`supports/thana_change_status/${thana.location_name}/${status}`)
        .then((response) => {
          if (response.data.success) {
            if (status) {
              thana.is_active = true;
              this.createSnackbar("Activated successfully");
            } else {
              thana.is_active = false;
              this.createSnackbar("Deactivated successfully");
            }
          } else {
            this.createSnackbar("Something went wrong");
          }
        })
        .catch((err) => {
          this.createSnackbar("Something went wrong");
        });
    },

    // Open add charge modal and make api call
    addCharge(item) {
      axios
        .get(`supports/show_charges/${item.id}`)
        .then((response) => {
          // console.log({ response });
          if (response.data.success) {
            this.assign_thana = response.data.data;
            this.dialog = true;
          } else {
            this.createSnackbar("Something went wrong");
          }
        })
        .catch((err) => {
          this.createSnackbar("Something went wrong");
        });
    },

    // Save thana charge
    onSaveThana() {
      let insertedCharge = {
        specification_id: -1,
        Area_name: this.selected_district.Area_name,
        Area_details: "",
        location_name: this.assign_thana.thana_name,
        height: 0,
        width: 0,
        length: 0,
        weight: 0,
        measument_unit: "kg",
        unit_price: this.assign_thana.unit_price,
        delivery_day: this.assign_thana.delivery_day,
        minimum_amount: this.assign_thana.minimum_amount,
      };

      axios
        .post(`supports/addcharge/`, insertedCharge)
        .then((response) => {
          if (response.data.success) {
            this.createSnackbar(
              `Inside city charge is added for ${insertedCharge.location_name}`
            );
            this.dialog = false;
          } else {
            this.createSnackbar(
              `Inside city charge is not added for ${insertedCharge.location_name}`
            );
            this.dialog = false;
          }
        })
        .catch((err) => {
          this.createSnackbar("Something went wrong");
          this.dialog = false;
        });
    },

    // Create snackbar
    createSnackbar(text, btnColor = "red") {
      this.text = text;
      this.btnColor = "red";
      this.snackbar = true;
    },
  },
  created() {
    this.getArea();
  },
};
</script>

<style>
</style>